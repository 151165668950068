import * as React from "react";
import Seo from "../components/seo";
import Layout from "../components/layout";
import styled from 'styled-components';
import Image from "../images/books.jpeg";


function Index() {

  return(
    <Layout>
    <Seo title="Welcome to Kat's Reading Corner | In partnership with Usborne Books" />

      <Content>
        <MainSection>
          <div>
            <StyledHeading as="h1">Welcome to Kat's Reading Corner</StyledHeading>
            <p>Kat's Reading Corner is in partnership with Usborne Books. Kat has worked in Early Years as a practitioner for over 10 years. There is nothing that she loves more than sharing stories with young children. Children can become passionate readers once they have found the right book for them to spark their imagination.</p>
            <p>You can shop with Kat by clicking the "Shop with Kat" button below to shop online, if you would like suggestions or advice from Kat, click "Contact me" button below.</p>  
            <LinkGroup>
              <LinkButton href="https://usborne.com/gb/partner/katsreadingcorner/" target="_blank">
                Shop with Kat
              </LinkButton>
              <LinkButton href="mailto:hello@katsreadingcorner.co.uk">
                Contact me
              </LinkButton>
            </LinkGroup>
          </div>
          <img src={Image} alt="" width="500px" height="375px"/>
        </MainSection>
      </Content>    
      </Layout>
  )
}

const Content = styled.section`
  background: #E6E6E6;
  margin-top: 40px;
  padding: 40px;  
  color: var(--chakra-colors-teal-500);

  @media(min-width: 1200px) {
    height: calc(100vh - 255px); 
  }

  p {
    color: black;
    margin-bottom: 20px;
  }
`

const MainSection = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  @media(min-width: 768px) {
    img {
      margin-left: 30px;
    }
  }

  @media(min-width: 767px) {
    flex-direction: row;
  }
`

const LinkGroup = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
`

const LinkButton = styled.a`
  text-decoration: none;
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: 2px solid transparent;
  outline-offset: 2px;
  line-height: 1.2;
  border-radius: var(--chakra-radii-md);
  font-weight: var(--chakra-fontWeights-semibold);
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  height: var(--chakra-sizes-10);
  min-width: var(--chakra-sizes-10);
  font-size: var(--chakra-fontSizes-md);
  padding-inline-start: var(--chakra-space-4);
  padding-inline-end: var(--chakra-space-4);
  background: var(--chakra-colors-teal-500);
  color: var(--chakra-colors-white);
  margin-right: 15px;

  &:hover {
    background: transparent;
    color: var(--chakra-colors-teal-500);
    outline: 2px solid var(--chakra-colors-teal-500);
  }
`

const StyledHeading = styled.h1`
  font-size: 32px;
  margin-bottom: 25px;  
  font-weight: bold;
`

export default Index;