import { Box, Flex, Link } from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { FaFacebookSquare, FaInstagramSquare } from 'react-icons/fa';
import styled from 'styled-components';


import Logo from "../images/logo.jpg";

const Header = ({ siteTitle }) => (
  <Box as="header" width="100%"  style={{ marginTop: "10px"}}>
      <Flex>
          <LogoLink
            as={GatsbyLink}
            to="/"
            color="blue"
            _hover={{ textDecor: 'none' }}
          >
            <img src={Logo} alt={siteTitle} width="200px" height="60px"/> 
          </LogoLink>
        <SocialLinks>
          <li><a href="https://www.facebook.com/katsreadingcorner" target="_blank" rel="noreferrer"><FaFacebookSquare/></a></li>
          <li><a href="https://www.instagram.com/kats.readingcorner/" target="_blank" rel="noreferrer"><FaInstagramSquare/></a></li>
        </SocialLinks>
      </Flex>
  </Box>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

const LogoLink = styled(Link)`
  display: flex;
  flex: 1;
  justify-content: center;
`

const SocialLinks = styled.ul`
  list-style: none;
  display: flex;
  justify-self: flex-end;
  align-items: center;
  margin: 0;
  font-size: 40px;
  margin: 0;

  li {
    padding: 0 4px;

    a {
      color: #32AFB3;
    }
  }
`


export default Header