// src/@chakra-ui/gatsby-plugin/theme.js
import { extendTheme } from '@chakra-ui/react';
import './styles/global-theme.css';
// import 'animate.css';

const theme = {
  colors: {
    color: {
      primary: 'blue',
      secondary: 'lightblue',
    }
  },
}

export default extendTheme(theme)