import { Box, Link } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Logo from "../../images/partnership-with.png";

const Footer = ({ siteTitle }) => (

    <FooterContainer as="footer" marginTop="2rem" fontSize="xl">
        <LeftCol>
          &copy; {siteTitle} {new Date().getFullYear()}
          <br/>
          <Box fontSize="15px"> Built by
          { ' ' }
          <Link
            isExternal
            href="https://www.thowardmedia.co.uk"
          >
            ThowardMedia
          </Link>
          </Box>
        </LeftCol>
        <img src={Logo} alt="" />
    </FooterContainer>
);

const FooterContainer = styled(Box)`
  background: #F7EB49;
  display: flex;
  padding: 20px;
`

const LeftCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

Footer.propTypes = {
    siteTitle: PropTypes.string,
}
  
Footer.defaultProps = {
    siteTitle: ``,
}


export default Footer